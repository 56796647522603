import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";

import "./style.scss";

const data = {
  loaders: {},
  errors: {
    main: "<div class=\"error-page\"><p>Oups ! Quelque chose s'est mal passé !<br/><small>C'est pas pro !</small></p></div>",
    menu: "<p>Erreur menu!</p>",
    sharedInfo: "<span>SharedInfo error!</span>",
  },
};

const routes = constructRoutes(
  document.querySelector("#single-spa-layout") as HTMLTemplateElement,
  data as any
);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
